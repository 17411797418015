/**
   * When an error occurs in ngOnInit, we can't stay on the page.
   * We throw this error to indicate to the custom error handler that is should navigate somewhere else.
   * For now, we navigate to the previous page, but we may want to change that later.
   * This error includes the original error, so all of the original error information is displayed/logged in the error handler.
   */
export class ComponentInitialisationError extends Error {

    constructor(public originalError: unknown, public navigateToSafePage = true) {
        super(ComponentInitialisationError.getMessage(originalError), { cause: originalError });

        // Set the prototype explicitly.
        //https://github.com/microsoft/TypeScript-wiki/blob/81fe7b91664de43c02ea209492ec1cea7f3661d0/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, ComponentInitialisationError.prototype);
    }

    private static getMessage(originalError: unknown): string {
        if (originalError instanceof Error) {
            return originalError.message;
        }

        if (originalError && typeof originalError === 'object' && "message" in originalError) {
            return String(originalError.message);
        }

        return String(originalError);
    }
}
